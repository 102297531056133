import { trackStructEvent } from "@snowplow/browser-tracker";

function pushDataLayer() {
  return [].slice
    .call(document.querySelectorAll("[data-tracking-label]"))
    .forEach((el) => {
      el.addEventListener("click", () => {
        if (
          typeof window !== "undefined" &&
          el.getAttribute("data-tracking-event").length > 0
        ) {
          if (el.getAttribute("data-tracking-page")) {
            window.dataLayer.push({
              event: "event",
              category: "ideas_report_hub",
              action: el.getAttribute("data-tracking-action"),
              label: el.getAttribute("data-tracking-label"),
            });
            trackStructEvent({
              category: el.getAttribute("data-tracking-category"),
              action: `${el.getAttribute("data-tracking-action")}_${el.getAttribute("data-tracking-label")}`,
              label: el.getAttribute("data-tracking-label"),
              property: el.getAttribute("data-tracking-page"),
              value: "",
            });
          } else {
            window.dataLayer.push({
              event: "event",
              category: "ideas_report_hub",
              action: el.getAttribute("data-tracking-action"),
              label: el.getAttribute("data-tracking-label"),
            });
            trackStructEvent({
              category: el.getAttribute("data-tracking-category"),
              action: `${el.getAttribute("data-tracking-action")}_${el.getAttribute("data-tracking-label")}`,
              label: el.getAttribute("data-tracking-label"),
              property: "",
              value: "",
            });
          }
        }
      });
    });
}

export default pushDataLayer;
