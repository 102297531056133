import React, {useEffect} from "react";
import Navigation from "./navigation";
import Helmet from "react-helmet";

import "~/scss/main.scss";

import pushDataLayer from '~/helpers/tracking-helper';

const Layout = ({ children, pageTitle }) => {
  useEffect(() => {
    pushDataLayer()
  }, [])

  return (
    <>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
    <div className="site">
      <div className="site__header">
        <Navigation />
      </div>
      <div className="site__content">
        {children}
      </div>
    </div>
    </>
  )
}

export default Layout;